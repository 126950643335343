import React from "react"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import InnerPage from "../components/innerPage"
import { graphql } from "gatsby"
const marked = require("marked");

const CookiesPolicy = ({data}) => {
  const {title, description} = data.strapiPoliticaCookies;

  return (
    <InnerPage>
      <InnerHeader>
        <Breadcrumb label="Politica de cookies"  />
      </InnerHeader>
      <div className="container py140md py5">
        <div className="row">
          <div className="col">
            <h2 className="mb3 text2md textPrimary">{title}</h2>
            <div dangerouslySetInnerHTML={{__html: marked(description)}} />
          </div>
        </div>
      </div>
    </InnerPage>
  )
}

export default CookiesPolicy

export const pageQuery = graphql`
  query Cookies {
    strapiPoliticaCookies {
      title
      description
    }
  }
`
